import { Advantages } from "./components/Advantages/Advantages"
import { Footer } from "./components/Footer/Footer"
import { OurProjects } from "./components/OurProjects/OurProjects"
import { Preview } from "./components/Preview/Preview"
import { Projects } from "./components/Projects/Projects"
import { StaffWorkedIn } from "./components/StaffWorkedIn/StaffWorkedIn"

function App() {
  return (
    <>
      <Preview />
      {/*<Projects />*/}
      {/*<OurProjects />*/}
      {/*<StaffWorkedIn />*/}
      <Footer />
    </>
  )
}

export default App
