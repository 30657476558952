import cl from "./Footer.module.css"
import { motion } from "framer-motion"
import { ReactComponent as Logo } from "../../icons/logo.svg"
import { ReactComponent as Call } from "../../icons/call.svg"
import { ReactComponent as WhatsApp } from "../../icons/whatsapp.svg"
import { ReactComponent as Telegram } from "../../icons/telegram.svg"
import { ReactComponent as VK } from "../../icons/vk.svg"
import { ReactComponent as Email } from "../../icons/email.svg"

export const Footer = () => {
  return (
    <footer className={cl.footer}>
      <div className={cl.logoWrapper}>
        <a href="#" title="TASKGROUP" className={cl.logo}>
          <Logo />
          <span style={{ width: "180px" }}>taskgoup</span>
        </a>
      </div>
      <ul className={cl.socialMediaList}>
        <li className={cl.socialMediaItem}>
          <a href="https://wa.me/79951255560" target="_blank" title="WhatsApp" className={cl.socialMediaLink}>
            <WhatsApp />
          </a>
        </li>
        <li className={cl.socialMediaItem}>
          <a href="https://t.me/TaskgroupRu" target="_blank" title="Telegram" className={cl.socialMediaLink}>
            <Telegram />
          </a>
        </li>
        <li className={cl.socialMediaItem}>
          <a href="https://vk.com/taskgroup" target="_blank" title="VK" className={cl.socialMediaLink}>
            <VK />
          </a>
        </li>
      </ul>
      <div className={cl.shortcut}>
        <motion.a
          whileHover={{ scale: 1.03 }}
          href="tel:+79951255560"
          title="Телефон поддержки"
          className={cl.shortcutItem}
        >
          <Call />
          +7 (995) 125-55-60
        </motion.a>
        <a
          href="mailto:admin@taskgroup.ru"
          title="Почта поддержки"
          className={cl.shortcutItem}
        >
          <Email />
          admin@taskgroup.ru
        </a>
      </div>
    </footer>
  )
}
