import { motion } from "framer-motion"
import cl from "./Preview.module.css"
import { ReactComponent as Logo } from "../../icons/logo.svg"

export const Preview = () => {
  return (
    <header className={cl.header}>
      <div className={cl.preview}>
        <Logo />
        <div className={cl.titleWrapper}>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.5,
                duration: 1,
              },
            }}
            className={cl.companyTitle}
          >
            Taskgroup
          </motion.h1>
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 1.5,
                duration: 1,
              },
            }}
            className={cl.slogan}
          >
            от малого до великого
          </motion.h2>
        </div>
      </div>
    </header>
  )
}
